.restaurant_item {
  flex: 1;
  outline: none;
  display: flex;
  cursor: pointer;
  min-height: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 9px;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: row;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);

  .restaurant_item_image {
    width: 116px;
    height: 116px;
    object-fit: contain;
    box-shadow: inset -1px 0 0 0 rgba(189, 195, 199, 0.4);
  }

  .restaurant_item_like {
    margin-top: 12px;
    margin-right: 10px;
    align-self: flex-start;
    flex-shrink: 0;
  }

  .restaurant_item_info {
    flex: 1;
    display: flex;
    overflow: hidden;
    margin-left: 20px;
    flex-direction: column;

    .restaurant_item_title {
      padding: 0;
      font-size: 18px;
      margin: 0 0 7px;
      font-weight: 700;
      word-wrap: break-word;
    }

    .restaurant_item_description {
      padding: 0;
      color: #7f8c8d;
      font-size: 14px;
      margin: 0 0 8px;
      font-weight: 400;
      word-wrap: break-word;
    }

    .restaurant_item_location {
      padding: 0;
      color: #bdc3c7;
      margin: 0 0 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      display: inline-block;
    }

    .restaurant_item_location_icon {
      width: 12px;
      height: 12px;
      margin-right: 2px;
    }
  }
}
