.spinner {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: white;
  bottom: -100px;

  transition: bottom ease-in-out 0.3s;
}

.spinner.loading {
  bottom: 51px;
}

.spinner div {
  margin: 6px;
  width: 51px;
  height: 51px;
  display: block;
  border-width: 6px;
  border-radius: 50%;
  position: absolute;
  border-style: solid;
  box-sizing: border-box;
  border-color: #e7ac5b transparent transparent transparent;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
