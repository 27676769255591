.add_dish_dialog_close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 8px;
}

.add_dish_dialog_close_icon {
  width: 14px;
  height: 14px;
}

.add_dish_dialog_image {
  border-radius: 6px;
}

.add_dish_dialog_row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 12px;
}

.modal-dialog-title.add_dish_dialog_title {
  margin-right: 10px;
}

.modal-dialog-description.add_dish_dialog_subtitle {
  text-align: center;
  margin-bottom: 4px;
}

.add_dish_dialog_description_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.add_dish_dialog_description {
  flex: 1;
  margin: 0;
  color: #bdc3c7;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 8px;
  font-style: italic;
  margin-right: 4px;
}

.add_dish_dialog_extra {
  color: #7f8c8d;
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0 6px 0;
}

.add_dish_dialog_mealtimes_text {
  color: #7f8c8d;
  font-size: 14px;
  font-weight: 400;
  margin: 12px 0 12px 0;
  text-align: center;
  font-weight: bold;
}

.add_dish_dialog_mealtimes {
  color: #7f8c8d;
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0 6px 0;
  text-align: center;
}

.add_dish_dialog_like {
  width: 24px;
  height: 24px;
}

.add_dish_comment {
  width: 100%;
}

.call-to-mobile {
  margin-top: 32px;
  text-align: center;
}

.textarea {
  resize: vertical;
  max-height: 100px;
}
