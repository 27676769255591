.pay_dialog {
}

.pay_dialog_title {
  margin: 0;
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  padding-top: 20px;
  text-align: center;
}

.pay_dialog_description {
  margin: 0;
  color: #7f8c8d;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 8px;
}

.input-group.tip {
  margin-top: 8px;
}
