.faq {
    max-width: 1200px;
    position: relative;
    width: 100%;
    margin: 0 auto;

    padding: 80px 0;

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .items-holder {
        margin: 40px 0;
    }

    h2 {
        font-weight: 700;
        font-size: 40px;
        color: #041C32;
    }
}