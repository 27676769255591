.header1 {}

.header4 {
    font-family: 'Open Sans';
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #041C32;
}

.header6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Open Sans';
    color: #041C32;
    margin: 0;
}