.faq-item {
    width: 792px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    cursor: pointer;
    margin-bottom: 12px;
    align-items: stretch;

    .answer {
        display: flex;
        align-items: center;

    }

    p {
        margin-top: 8px;
        font-size: 16px;
        color: #04293A;
    }

    h3 {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        color: #041C32;
    }
}

.full-width {
    width: 100%;
}