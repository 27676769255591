.featured-item {
    width: 282px;

    img {
        aspect-ratio: 588 / 331;
        width: 100%;
        margin-bottom: 24px;
        object-fit: cover;
    }

    .tag {
        font-weight: 400;
        font-size: 12px;
        color: #F86600;
    }

    .blog-row {
        display: flex;
        align-items: center;

        .blog-category {
            color: #F86600;
            border: 1px solid #F86600;
            border-radius: 4px;
            padding: 4px 8px;
        }

        .date {
            margin-left: 24px;
        }
    }

    h3 {
        color: #041C32;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        margin-top: 8px;
    }

    .location {
        margin-top: 8px;
        display: flex;
        align-items: center;

        p {
            margin-left: 8px;
            font-size: 14px;
            color: #9E9E9E;
        }
    }
}

.blog {
    width: 384px;
}