.filters {
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    padding: 20px;

    font-weight: 800;
    font-size: 20px;

    color: #e7ac5b;
    background-color: lighten(#e7ac5b, 25%);
  }

  .radio-group {
    margin: 0;
    padding: 10px 20px;

    .label {
      display: none;
    }

    label {
      margin-left: 0;
      color: #000;

      font-size: 16px;
      font-weight: 600;
      text-transform: none;
    }

    .radio-option {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 100%;
    }
  }

  .option {
    padding: 10px 20px;

    .checkbox_wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 100%;

      .checkbox_text {
        color: #000;

        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .searchbar {
    height: 56px;
    border: #bdc3c7 1px solid;
  }
}
