.restaurant_menu_item_container {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 1px solid rgba(189, 195, 199, 0.4);

  &.allergy {
    border-left: 2px solid #c0392b;
  }

  &.standalone {
    flex: 1;
    outline: none;
    display: flex;
    cursor: pointer;
    min-height: 90px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 9px;
    margin-bottom: 20px;
    align-items: center;
    flex-direction: row;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }

  .restaurant_menu_item_wrapper {
    padding: 16px;
    display: flex;
    flex: 1;
    flex-direction: row;

    .restaurant_menu_item_info {
      flex: 1;
      display: flex;
      padding-right: 16px;
      flex-direction: column;
    }

    .restaurant_menu_item_image {
      width: 96px;
      height: 96px;
      object-fit: cover;
      border-radius: 6px;
    }

    .restaurant_menu_item_title_wrapper {
      color: #000000;
      display: flex;
      margin-bottom: 4px;
      flex-direction: row;

      .restaurant_menu_item_title {
        flex: 1;
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        padding-right: 10px;
      }

      .restaurant_menu_item_like {
        width: 16px;
        height: 16px;
      }
    }

    .restaurant_menu_item_restaurant {
      margin-top: 0;
      margin-bottom: 6px;
      color: #e7ac5b;
      font-size: 12px;
      font-weight: 700;
    }

    .restaurant_menu_item_description {
      color: #bdc3c7;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      max-height: 36px;
      margin: 0 0 6px 0;
      font-style: italic;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .restaurant_menu_item_extra {
      color: #7f8c8d;
      font-size: 12px;
      font-weight: 400;
      margin: 2px 0 6px 0;
    }

    .restaurant_menu_item_price_block {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    .restaurant_menu_item_price {
      margin: 0;
      color: #e7ac5b;
      font-size: 16px;
      font-weight: 700;

      &.action_active {
        color: #bdc3c7;
        font-size: 14px;
        text-decoration: line-through;
        margin-left: 8px;
      }
    }
  }

  .restaurant_menu_item_allergy {
    color: white;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    background-color: #e7ac5b;
  }
}
