.fair-special-header {
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p,
  h2 {
    text-align: center;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  p {
    font-size: 16px;
    opacity: 0.6;
    margin-top: 0;
    margin-bottom: 16px;
  }
}
