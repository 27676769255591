@import '../../styles/variables';

.sorting-wrapper {
  background-color: $primary;
  border-radius: 4rem;
  bottom: 0.5em;
  position: absolute;
  right: 0.5em;
  padding: 0.25em;

  .sorting-icon {
    border: 2px solid $primary;
    height: 1.5rem;
    width: 1.5rem;

    &.reverse {
      transform: rotate(180deg);
    }
  }
}