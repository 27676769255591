.filters_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px 20px;
  flex-direction: row;
  margin-bottom: -8px;

  & > * {
    margin-left: 5px;
    margin-right: 5px;
  }
}
