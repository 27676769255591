.order_item {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(189, 195, 199, 0.4);

  &.ordered {
    padding: 20px 16px;
  }
}

.order_item_status {
  color: #bdc3c7;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 0 6px;
  font-style: italic;

  &.submitted {
    color: #acb2b6;
    font-weight: 700;
  }
}

.order_item_title_wrapper {
  display: flex;
  color: #7f8c8d;
  margin-bottom: 6px;
  flex-direction: row;

  .order_item_title {
    flex: 1;
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    padding-right: 10px;
  }

  .order_item_delete {
    width: 20px;
    height: 20px;
    display: none; // temp
  }
}

.order_item_description {
  color: #7f8c8d;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 6px 0;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.order_item_extra {
  color: #bdc3c7;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 10px 0;
  font-style: italic;
}

.order_item_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order_item_ordered_amount {
  color: #bdc3c7;
  font-size: 16px;
  font-weight: 400;
  margin-right: 6px;
}

.order_item_ordered_title {
  color: #7f8c8d;
  font-size: 16px;
  font-weight: 700;
}

.order_item_price {
  color: #222d39;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 700;
}

.order_item_ordered_subtitle {
  color: #bdc3c7;
  font-size: 12px;
  padding-top: 10px;
  font-weight: 400;
}
