.landing-page {
    background-color: #fff;

    h1 {
        color: #041C32;
        font-size: 56px;
        font-weight: 700;
        line-height: 64px;
        text-align: left;
        margin-bottom: 16px;
        font-family: "Open Sans";
        margin-top: 0;
    }

    h2 {
        color: #7F8C8D;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.71px;
        line-height: 26px;
        text-align: left;
        margin: 0;
        font-family: "Open Sans";
        margin-bottom: 25px;
    }

    h3 {
        color: #183F57;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.85px;
        text-align: left;
        margin-top: 24px;
        font-family: "Open Sans";
        margin-bottom: 12px;
    }

    p {
        color: #7F8C8D;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin: 0;
        font-family: "Open Sans";
    }
}