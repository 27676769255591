@import '../../../../../styles/variables';

.item-selector {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    padding: 0 -4px;

    .item {
        display: flex;
        justify-content: center;
        padding: 9px;
        border: 1px solid #BDBDBD;
        border-radius: 4px;
        margin: 4px;
        font-family: 'Open Sans';

        p {
            margin: 0;
            color: #041C32;
            font-size: 14px;
            line-height: 22px;
            font-weight: 700;
        }

        &.selected {
            background-color: $primaryNew;
            border: 1px solid $primaryNew;

            p {
                color: #fff;
            }
        }
    }
}

.item-selector--4column {
    grid-template-columns: repeat(4, 1fr) !important;
}

.item-selector--3column {
    grid-template-columns: repeat(3, 1fr) !important;
}

.item-selector--2column {
    grid-template-columns: repeat(2, 1fr) !important;
}

.item-selector--1column {
    grid-template-columns: 1fr !important;
}

.item-selector--fix-height {
    height: 100%;
}