.middle-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    padding: 80px 0;
    background-color: #fff;

    .container {
        max-width: 1200px;
        position: relative;
        width: 100%;
        margin: 0 auto;
        display: flex;

        div:nth-child(1) {
            margin-right: 24px;
        }
    }
}


@media screen and (max-width: 800px) {
    .middle-section {
        flex-direction: column;
        padding: 60px 40px;

        .card {
            &:nth-child(2) {
                margin-left: 0px;
                margin-top: 32px;
            }
        }
    }
}