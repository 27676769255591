.price_range_container {
  color: #bdc3c7;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selectable {
    font-size: 24px;
    padding: 18px;
  }

  .selected {
    color: #e7ac5b;
  }

  .iconWrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    margin-right: 56px;
  }

  .reset {
    width: 18px;
    height: 18px;
    margin: 0 20px;
    position: absolute;
    right: 20px;
  }
}
