.input-group {
  &.label-value {
    &.highlighted {
      label {
      }
      p {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
