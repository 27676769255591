.quick-actions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;

  .quick-action {
    flex: 1;

    @media screen and (max-width: 1024px) {
      min-width: 50%;
    }
  }
}
