.map-button {
  width: 158px;
  height: 40px;
  padding: 8px;
  border: #bdc3c7 1px solid;
  border-radius: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  span {
    font-size: 15px;
    color: #000;
    font-weight: 600;
  }
}
