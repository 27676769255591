@import '../../styles/variables';

.restaurant_eatdrink_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0px;
    background-color: white;
}

.restaurant_eatdrink_item {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

.restaurant_eatdrink_button {
    border: none;
    width: 120px;
    outline: none;
    color: #bdc3c7;
    font-size: 16px;
    font-weight: 700;
    padding-top: 12px;
    border-radius: 8px;
    padding-bottom: 12px;
    background-color: transparent;

    &.selected {
        color: white;
        background-color: $primary;
    }
}