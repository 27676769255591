@import '../../styles/variables';

.allergy-row {
  margin-bottom: 12px;

  .allergy-row-content {
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }

    .allergy-name {
      flex: 1;
      color: #bdc3c7;
      font-weight: bold;
    }

    .checkbox {
      width: 20px;
      height: 20px;
      margin-left: auto;
      border-radius: 2px;
      border: solid 2px #bdc3c7;
    }

    .right_icon {
      width: 20px;
      height: 20px;
      margin-left: auto;
    }

    .right_icon_close {
      width: 16px;
      height: 16px;
      margin-left: auto;
      margin-right: 2px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    .checkbox {
      background-color: $primary;
      border: solid 2px $primary;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}