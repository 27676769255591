.select_container {
  position: relative;

  .select {
    width: 100%;
    color: black;
    border: none;
    height: 48px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    appearance: none;
    caret-color: #e7ac5b;
    background-color: transparent;
    border-bottom: 2px solid #bdc3c7;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
    }

    &[disabled] {
      color: #a0a0a0;
    }
  }

  &.invalid {
    label {
      color: red;
    }

    .select {
      border-bottom: 2px solid red;
      color: red;
    }
  }

  .select_icon {
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: row;
    pointer-events: none;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
