.alt_action {
    display: flex;
    cursor: pointer;
    padding-left: 20px;
    flex-direction: row;
    padding-right: 20px;
    align-items: center;
    justify-content: center;

    .text {
        color: #7f8c8d;
        font-size: 16px;
        font-weight: 700;
        padding-right: 10px;
    }

    .icon {
        width: 20px;
        height: 20px;
    }
}
