.footer {
    background: #041C32;
    width: 100%;
    height: 537px;
    padding-top: 60px;

    .container {
        max-width: 1200px;
        position: relative;
        width: 100%;
        margin: 0 auto;

        .row {
            width: 100%;
            display: flex;
            flex-direction: row;

            .logo {
                width: 120px;
            }

            .intro {
                color: white;
                margin-top: 24px;
                margin-bottom: 24px;
                width: 588px;
                margin-right: 24px;
            }
        }

        .column-holder {
            display: flex;
            justify-content: space-between;
            width: 100%;

            margin-top: 105px;

            .column {
                display: flex;
                flex-direction: column;

                .column-header {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                }

                a {
                    color: #E0E0E0;
                    margin-top: 12px;
                    font-size: 14px;
                }


            }
        }

        .line {
            margin: 60px 0;
            height: 1px;
            width: 100%;
            background-color: #ffffff;
            opacity: 0.25;
        }

        .social-button {
            background: rgba(255, 255, 255, 0.15);
            width: 48px;
            height: 48px;
            border-radius: 48px;
            margin-right: 12px;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background: rgba(255, 255, 255, 0.25);
            }
        }

        .bottom {
            justify-content: space-between;
            align-items: center;

            .copyright {
                color: #E0E0E0;
                font-size: 14px;
            }

            .logo-holder {
                img {
                    margin-left: 12px;
                }
            }
        }

    }
}