.notification_item {
  color: white;
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 500;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  will-change: opacity;
  padding: 10px 20px 10px 10px;

  &.success {
    background-color: #27ae60;
  }

  &.error {
    background-color: #c0392b;
  }

  .notification_message {
    margin: 0;
    padding: 0 0 0 5px;
  }

  &.enter {
    opacity: 0;
  }

  &.enter_active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
  }

  &.exit {
    opacity: 1;
  }

  &.exit_active {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
}
