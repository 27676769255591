.category_header {
    color: #e7ac5b;
    padding: 30px 20px;
    background-color: rgba(231, 172, 91, 0.32);

    &.single {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.category_header_title {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;

    &.single {
        margin: 0;
        padding: 0;
    }
}

.category_header_description {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}
