@import '../../styles/variables';

.drawer_menu_item {
    height: 64px;
    outline: none;
    display: flex;
    cursor: pointer;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 14px;
    border-radius: 9px;
    padding-left: 16px;
    align-items: center;
    flex-direction: row;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);


    .icon {
        margin-right: 12px;
    }

    .title {
        flex: 1;
        margin: 0;
        padding: 0;
        color: $primary;
        font-size: 18px;
        font-weight: 700;
        text-align: start;
    }

    .drawer_menu_item_arrow {
        width: 24px;
        height: 24px;
        transform: rotate(-90deg);
    }


}