.breadcrumbs {
    padding: 16px;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;

    .holder {
        background: #FEE0CC;
        border-radius: 5px;
        overflow: hidden;

        display: flex;
        flex-direction: row;

        .step {
            width: 100%;
            justify-content: center;
            display: flex;
            border-radius: 5px;

            .content {
                padding: 4px;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;

                span {
                    font-size: 12px;
                    color: #fff;
                }
            }

            .arrow-br {
                width: 16px;
            }

            .selected-icon path {
                fill: #FFFFFF !important;
            }

            .selected-arrow {
                background: #F86600;
                width: 24px;
                height: 24px;
                transform: rotate(45deg);
                margin-left: -14px;
                margin-top: 2px;
                border-radius: 2px;
            }

            .fill-white {
                fill: #fff;
            }

            .bg-selected {
                background: #F86600;
            }
        }

        .selected {

            .content {
                background: #F86600;
                margin-top: -2px;
                z-index: 1;
            }
        }
    }
}