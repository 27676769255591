.block {
    display: flex;
    flex-direction: column;

    .image {
        aspect-ratio: 588 / 331;
    }

    .title {
        color: #041C32;
        margin-top: 24px;
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 700;
    }

    .intro {
        color: #04293A;
    }
}