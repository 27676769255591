@import '../../styles/variables';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  vertical-align: baseline;
  background-color: #eeeeee;
  font-family: 'Ubuntu', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

section {
  position: relative;
}

$spaceamounts: (
  0,
  1,
  2,
  4,
  8,
  10,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  30,
  32,
  36,
  40,
  48,
  50,
  60,
  70,
  80,
  90
);
$sides: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-#{$space} {
      padding: #{$space}px !important;
    }

    .m-#{$space} {
      margin: #{$space}px !important;
    }
  }
}

.bg-color {
  background-color: #1b2c45;
}

.bg-broken-white {
  background-color: #fafafa;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.full-width {
  width: 100%;
}

.flex-center {
  align-items: center;
}

.text-color-accent {
  color: #428ccb;
}

.text-color-white {
  color: white;
}

.bold {
  font-weight: 800;
}

.text-small {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.pointer {
  cursor: pointer;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #fafafa !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z--1 {
  z-index: -1;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.center {
  margin: 0 auto;
}

.full-image {
  width: 100%;
  height: 100%;
}

.cover {
  object-fit: cover;
}

.hide {
  display: none;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .full-width {
    max-width: 800px;
    margin: auto;
  }

  .dont-show-on-desktop {
    display: none;
  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .full-width {
    max-width: 800px;
    margin: auto;
  }

  .dont-show-on-desktop {
    display: none;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  //CSS
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  //CSS
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  //CSS
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  //CSS
}