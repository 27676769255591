.collapsable-card {
    display: flex;
    align-items: center;

    .card {
        width: 520px;
        display: flex;
        justify-content: space-between;
        padding: 0 24px 12px;
        cursor: pointer;
        margin-bottom: 12px;
        align-items: stretch;

        .icon {
            fill: #E7AC5B;
            width: 32px;
            height: 32px;
            margin: auto 0;
        }

        .content {
            margin: 0 24px;
            width: 1000px;

            h3 {
                color: #041C32;
                font-size: 24px;
            }

            .text {
                font-size: 14px;
                line-height: 22px;
                margin-top: 8px;
                margin-bottom: 12px;
                color: #04293A;

            }
        }
    }

    .flex {
        display: flex;
    }


}