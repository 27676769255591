.pay_dialog {
}

.modal-dialog-title.pay_dialog_secondary_title {
  font-size: 16px;
}

.pay_dialog_description {
  margin-bottom: 8px;
}

.register-tip {
  display: block;
  margin-top: 8px;
}

.pay_dialog_userdata {
  margin: 0;
  color: #7f8c8d;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.full-width {
  flex: 1;
}

.donation-checkbox {
  margin-top: 16px;
}

.pay_dialog_menuitem {
  margin-bottom: 8px;
}

.pay_dialog_menuitem_title {
  display: flex;
  justify-content: space-between;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
}

.pay_dialog_menuitem_sidedish {
  display: flex;
  justify-content: space-between;
  color: #7f8c8d;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}

.pay_dialog_dishes {
  margin-top: 8px;
}

.pay_dialog_total {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: #000000;
  font-size: 16px;
}

.pay_dialog_total_price {
  color: #e7ac5b;
}
