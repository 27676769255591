.btn-submit {
    margin: 0px 20px 20px 20px;
}

.home_logo {
    width: 160px;
    height: 160px;
    z-index: 1;
    display: inline-block;
}

.home_content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.account_confirm_logo {
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
}

.account_confirm_greeting {
    margin-top: 84px;
    margin-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 20px;
}