.reservation-question {
    padding: 16px;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;

    display: flex;

    img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
    }

    .speech {
        padding: 8px 16px;
        border: 1px solid #E0E0E0;
        border-radius: 0px 8px 8px 8px;
    }
}