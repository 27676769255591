.restaurant_details {
    display: flex;
    flex-direction: column;
    will-change: max-height, opacity;

    &.enter {
        opacity: 0;
        max-height: 0;
    }

    &.enter_active {
        opacity: 1;
        max-height: 800px;
        transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
    }

    &.exit {
        opacity: 1;
        max-height: 800px;
    }

    &.exit_active {
        opacity: 0;
        max-height: 0;
        transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
    }
}

@media only screen and (min-width: 600px) {
    .restaurant_details {
        max-width: 800px;
        margin: auto;
    }
}

.restaurant_details_separator {
    width: 100%;
    height: 1px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: rgba(189, 195, 199, 0.4);
}

.restaurant_details_price_range_container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.restaurant_details_description {
    color: #7f8c8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 20px 0;
    padding-left: 16px;
    padding-right: 16px;
}

.restaurant_details_information {
    color: black;
    font-size: 16px;
    font-weight: 500;
    padding-left: 20px;
    padding-bottom: 6px;

    .value {
        color: #e7ac5b;
    }
}

.restaurant_details_opening_times {
    margin-bottom: 20px;
    color: black;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;

    li {
        display: flex;
    }

    .open {
        color: #e7ac5b;
    }

    .closed {
        color: #e74c3c;
    }
}

.restaurant_details_openings_times_list {
    padding: 0;
    margin: 0 0 0 4px;
    list-style-type: none;
}

.restaurant_details_opening_time_item {

}

.restaurant_details_day {
    opacity: .7;
    width: 100px;
    font-weight: 400;
    display: inline-block;

    &.today {
        opacity: 1;
        font-weight: 500;
    }
}

.restaurant_details_time {
    opacity: .7;
    font-weight: 400;

    &.today {
        opacity: 1;
        font-weight: 500;
    }
}

.restaurant_details_arrow_up {
    width: 32px;
    height: 32px;
    margin: 0 auto 20px auto;
    transform: rotate(180deg);
}

.restaurant_details_arrow_opening_times_down {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-top: -2px;

    &.up {
        transform: rotate(180deg);
    }
}
