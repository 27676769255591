.aspect_ratio_container {
    width: 100%;
    position: relative;
}

.aspect_ratio_container_wrapper {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

}
