@import '../../../../styles/variables';

.search-bar-header {
    width: 384px;

    position: relative;

    input {
        width: 100%;
        padding: 14px 16px;

        border-radius: 24px;
        border: 1px solid #E0E0E0;

        &::placeholder {
            color: #9E9E9E
        }
    }

    .icon-button {
        border-radius: 50px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $primaryNew;

        position: absolute;
        top: 4px;
        right: 4px;

        cursor: pointer;

        &:hover {}
    }
}