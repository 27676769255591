.legend-entry {
  display: flex;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  .color {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 9px;
  }

  .label {
    margin-left: 8px;
    color: #7f8c8d;
    font-size: 14px;
    font-family: 'Ubuntu', sans-serif;
  }
}
