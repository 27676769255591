.walkthrough_content {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;

    .title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .description {
        color: #7f8c8d;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto 16px auto;
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;

        .back-button {
            flex-direction: row-reverse;

            .back-button-icon {
                margin-left: 0;
            }
        }
    }
}
