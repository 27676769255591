.yes_no_dialog_title {
  font-size: 15px;
  text-align: start;
}

.yes_no_dialog_options {
  display: flex;
  margin-top: 14px;
  flex-direction: row;
}

.yes_no_dialog_button {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  padding-top: 6px;
  margin-left: 20px;
  padding-bottom: 6px;
  color: rgba(black, 0.6);
  background-color: transparent;

  &.highlight {
    color: black;
  }
}
