@import '../../../../../../styles/variables';

.step5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 16px;

    .extra {
        color: $primaryNew;
        font-weight: 700;
        text-align: center;
    }
}

.reservation-input {
    border: 1px solid #bdbdbd !important;
    border-radius: 4px;
    padding: 16px;

    &-container {
        width: 100%;

        &:last-child {
            margin-top: 30px;
        }
    }

    &-label {
        color: #041c32;
        padding-bottom: 8px;
    }
}