.avatar {
  z-index: 10;
  width: 148px;
  height: 148px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 4px solid;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid;
  }
}
