.restaurant-popup {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;

    h1 {
      color: #000000;
      font-size: 24px;
      letter-spacing: -0.86px;
      text-align: center;
      margin: 0;
      margin-right: 16px;
    }
  }

  .profile-image {
    width: 100%;
  }

  .divider {
    margin: 24px 0;
  }

  .location {
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;

    .location-icon {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

    span {
      margin: 6px 0;
      color: #bdc3c7;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
