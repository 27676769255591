@import '../../../styles/variables';

.radio-button {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  border: 2px solid #bdc3c7;
  border-radius: 100px;

  &.selected {
    border: 2px solid $primary;

    .inner-radio-button {
      width: 12px;
      height: 12px;
      display: block;
      background-color: $primary;
      border-radius: 100px;
    }
  }

  .inner-radio-button {
    display: none;
  }
}