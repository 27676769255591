@import '../../styles/variables';

.profile_header {
  width: 100%;
  height: 240px;
  position: relative;
  background-color: white;
}

.profile_header_bg {
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  position: absolute;
}

.login_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  height: 250px;
  background-image: linear-gradient(0deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)),
    url(../../assets/images/header_no_gradient.png);
  background-size: cover;
  background-position: center;

  .avatar {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-bottom: 16px;

    .profile_picture_edit {
      right: 0;
      bottom: 0;
      width: 40px;
      height: 40px;
      position: absolute;
      border-radius: 50%;
      background-color: $primary;
    }

    .profile_picture_edit_icon {
      width: 40px;
      height: 40px;
      padding: 10px;
    }
  }
}

.profile_title {
  padding: 0 16px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.profile_order_history {
  margin: 4px auto;
  font-size: 14px;

  padding: 0 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
}

.filter-menu {
  display: flex;
  color: #e7ac5b;
  align-items: center;
  justify-content: space-between;

  .add-new {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../../assets/icons/edit.svg);
  }
}