@import '../../../../../../styles/variables';

.step3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 16px;
    overflow-y: scroll;

    .extra {
        color: $primaryNew;
        font-weight: 700;
        text-align: center;
    }
}