.ticket-form {
  padding: 24px 16px 24px 16px !important;

  .header-image {
    width: 450px;
    height: 250px;
    margin: auto;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    align-items: center;

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }
}