.input-group {
  margin-top: 24px;

  &:first-child {
    margin-top: 0px;
  }

  label {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.invalid {
    label {
      color: red;
    }
  }

  .flex-block {
    display: flex;

    .select_container {
      flex: 1;
      width: 0px;
      margin: 0;

      .select {
        width: 100%;
      }

      &:nth-child(2):not(:last-child) {
        margin: 0 24px;
      }

      &:nth-child(2):last-child {
        margin-left: 24px;
      }
    }
  }
}
