.message_dialog_title {
    color: black;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.message_dialog_description {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 0 0 0;
    color: rgba(black, .4);
}

.message_dialog_options {
    display: flex;
    margin-top: 14px;
    flex-direction: row;
}

.message_dialog_button {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    padding-top: 6px;
    margin-left: 20px;
    padding-bottom: 6px;
    color: rgba(black, .6);
    background-color: transparent;
}


