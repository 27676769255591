.input {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #bdc3c7;
  align-items: center;
  flex: 1;

  &.hide-underline {
    border-bottom: none;
  }

  input {
    flex: 1;
    color: black;
    border: none;
    height: 48px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    caret-color: #e7ac5b;
    background-color: transparent;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
    }

    &.invalid {
      border-color: red;
      border-width: 2px;
      color: red;
    }
  }

  .input-prefix {
    margin-right: 8px;
    margin-bottom: 2px;
  }
}
