.tip {
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    border-top: 1px solid rgba(189, 195, 199, 0.4);
    border-bottom: 1px solid rgba(189, 195, 199, 0.4);

    .text {
        color: #222d39;
        font-size: 16px;
        font-weight: 700;

        &.price {
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
