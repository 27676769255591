.category-list {
  margin: 20px 0;
  padding: 0 20px;

  display: flex;
  justify-content: flex-start;
  position: relative;

  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
