.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 16px;


    .text-bubble {
        position: relative;
        border: 1px solid #E0E0E0;
        margin-top: 24px;
        padding: 24px 16px;
        border-radius: 8px;


        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 24px;
            height: 24px;
            border: 24px solid transparent;

            background-color: #fff;
            margin-left: -12px;
            margin-top: -13px;

            transform: rotate(45deg);

            border: 1px solid #E0E0E0;
            border-right: 0;
            border-bottom: 0;
        }

        .copy {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #041C32;
            margin: 0;
            margin-top: 12px;
            margin-bottom: 24px;
        }

        .divider {
            height: 1px;
            background-color: #E0E0E0;
            width: 100%;
            margin: 16px 0;
        }

        .row {
            display: flex;
            align-items: center;

            p {
                margin: 0 0 0 10px;
            }
        }
    }

    .fill-dark {
        path {
            fill: #041C32;
        }
    }
}