@import '../../../styles/variables';

.floating-button {
  width: 60px;
  right: 16px;
  height: 60px;
  bottom: 16px;
  padding: 0;
  border: none;
  outline: none;
  position: fixed;
  background-color: transparent;
  z-index: 999;

  &.back-to-top {
    bottom: 16px;
    right: -60px;
    padding: 0;
    transition: right ease-in-out 0.2s;

    &.visible {
      right: 16px;
    }
  }

  .circle {
    flex: 1;
    width: 60px;
    height: 60px;
    display: flex;
    position: relative;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.4);

    &:hover {
      background-color: #092b41;
    }
  }

  .number {
    right: 0;
    bottom: 0;
    width: 18px;
    height: 18px;
    color: white;
    font-size: 11px;
    line-height: 18px;
    position: absolute;
    border-radius: 50%;
    background-color: #c62828;
  }
}