.user-greeting {
  .modal-dialog-title {
    text-align: center;
    font-size: 20px;
  }

  .modal-dialog-description {
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
  }
}
