.button {
  &.reservation-button {
    width: auto;
    position: fixed;
    bottom: 0;
    left: 16px;
    right: 16px;
    bottom: 16px;
  }
}
