@import '../../../styles/variables';

.filter {
  color: white;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 10px;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  background-color: $primary;
  cursor: pointer;

  .filter_text {
    cursor: default;
  }
}

.filter_price {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.filter_price_label {
  margin-right: 8px;
}

.filter_close {
  width: 12px;
  fill: white;
  height: 12px;
  margin-left: 10px;
}