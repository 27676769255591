.textarea {
    resize: none;
    outline: none;
    padding: 12px;
    color: #222d39;
    font-size: 16px;
    font-weight: 500;
    border-radius: 9px;
    background-color: transparent;
    border: 1.5px solid rgba(189, 195, 199, 0.4);
}
