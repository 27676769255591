.restaurant-occupation {
    display: flex;
    align-items: center;
    background-color: white;
    padding-right: 24px;
    cursor: pointer;

    &.border-bottom {
        border-bottom: 1px solid rgba(189, 195, 199, 0.4);
    }

    .restaurant_item_image {
        width: 116px;
        height: 116px;
        object-fit: contain;
    }

    .occupation-indicator {
        color: #7f8c8d;
        font-size: 14px;
        font-weight: 400;

        &.medium {
            color: #e7ac5b;
            font-weight: 700;
        }

        &.high {
            color: #e74c3c;
            font-weight: 700;
        }
    }

    .restaurant_item_info {
        flex: 1;
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;

        .restaurant_item_title {
            padding: 0;
            font-size: 18px;
            margin: 0 0 7px;
            font-weight: 700;
            word-wrap: break-word;
        }

        .restaurant_item_description {
            padding: 0;
            color: #7f8c8d;
            font-size: 14px;
            margin: 0 0 8px;
            font-weight: 400;
            word-wrap: break-word;

        }

        .restaurant_item_location {
            padding: 0;
            color: #bdc3c7;
            margin: 0 0 8px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            display: inline-block;
        }

        .restaurant_item_location_icon {
            width: 12px;
            height: 12px;
            margin-right: 2px;
        }
    }
}