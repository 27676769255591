.restaurant-page {
  padding-bottom: 86px;

  .floating-button {
    &.back-to-top {
      bottom: 88px !important;
      right: -60px;
      padding: 0;
      transition: all ease-in-out 0.2s;

      &.visible {
        right: 16px;
      }
    }
  }
}

.hidden {
  overflow: hidden !important;
}
