@import '../../../styles/variables';

.button {
  color: white;
  border: none;
  outline: none;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  padding: 16px 24px;
  flex-direction: row;
  align-items: center;
  border-radius: 28px;
  background-color: $primary;

  &.danger {
    background-color: #c62828;
  }

  &.disabled {
    opacity: 0.25;
    cursor: default;
  }

  &.outline {
    border: 2px solid $primary;
    background-color: transparent;
    color: $primary;
  }

  &.flat {
    background-color: transparent;
    color: $primary;
  }

  &.centered-text {
    .text {
      text-align: center;
    }
  }

  &.fb {
    background-color: #435ba4;
  }

  .text {
    flex: 1;
    margin: 0;

    &.align-end {
      text-align: end;
    }
  }

  &.small-icon {
    height: 56px;
    padding-left: 12px;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-left: 12px;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}