.category {
  position: relative;

  min-width: 120px;
  height: 88px;
  margin: 5px;

  border-radius: 10px;

  background-position: center;
  background-size: cover;

  .name {
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: 700;
  }
}
