@import '../../../../../../styles/variables';

.step2 {
    height: 100%;
    padding: 16px;
}

.react-datepicker {
    width: 100%;
    border: none;

    &__month,
    &__month-container {
        width: inherit;
        margin: 0;
    }

    &__current-month {
        margin-bottom: 24px;
    }

    &__header {
        border: none;
        background-color: unset;
        padding-bottom: 0;
        margin-bottom: 24px;
    }

    &__day,
    &__day-name {
        width: calc((100% - 55px) / 7);
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin: 0 4px;

    }

    &__day--disabled {
        text-decoration: line-through;
    }

    &__day--disabled,
    &__day--outside-month {
        background-color: #EEE;
        color: #9E9E9E;
    }

    &__day-names {
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    &__day {
        border-radius: 4px;
        border: #EEE solid 1px;
        margin: 4px;
        padding: 5px 12px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
    }

    &__navigation-icon::before {
        border-width: 2px 2px 0 0;
        border-radius: 1px;
    }
}

span[class="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"]::before {
    border-color: $primaryNew !important;
}

div:has(div[class="react-datepicker"]) {
    width: 100%;
}