.dialog .actions {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  &.horizontal {
  }

  &.vertical {
    flex-direction: column;
  }

  .button {
    flex: 1;
  }
}
