.featured-items {
    max-width: 1200px;
    position: relative;
    width: 100%;
    margin: 0 auto;

    padding: 80px 0;

    h2 {
        font-size: 40px;
        font-weight: 700;
        color: #041C32;
        margin-bottom: 40px;
    }

    .featured-header {
        justify-content: space-between;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

}

.off-white {
    background-color: #fafafa;
}