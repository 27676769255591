.search_toolbar {
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    display: flex;
    z-index: 1000;
    position: fixed;
    background: #ffffff;
    padding-left: 10px;
    flex-direction: row;
    padding-right: 10px;
    border-bottom: solid 1px rgba(189, 195, 199, 0.4);
}

.search_toolbar_button {
    border: none;
    padding: 10px;
    outline: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.search_toolbar_input {
    height: 64px;
    border-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
}
