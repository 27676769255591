.content-section {
  padding: 0 16px 24px 16px;
  overflow: auto;

  .profile-info {
    margin-top: 24px;
  }

  .button {
    margin-top: 24px;

    &.destructive {
      background-color: red;
    }
  }

  .card {
    p.text {
      margin: 4px 0;
      color: #222d39;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
