@import '../../styles/variables';

.icon-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  .icon {
    height: 20px;
    margin-right: 10px;
    margin-bottom: 4px;
    width: 20px;
  }

  .pop-up {
    align-items: center;
    background-color: $primary;
    border-radius: 4px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    position: absolute;
    padding: 4px 10px;
    text-align: center;
    top: -40px;
    z-index: 1;

    &:after {
      background-color: $primary;
      bottom: -10px;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      content: '';
      height: 10px;
      position: absolute;
      width: 20px;
    }
  }
}