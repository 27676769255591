.reservation-entry-ticket {
  .header-image {
    background-color: white;
    width: 450px;
    height: 250px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    align-items: center;

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  .restaurant-name {
    margin-top: 16px;
  }

  .reservation-data {
    margin-top: 8px;

    p {
      margin: 0;
      text-align: start;
    }
  }

  .ticket-extra-information {
    margin-top: 16px;
    font-style: italic;
  }
}