.table_header {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-bottom: 1px rgba(189, 195, 199, 0.4);

    .text {
        flex: 1;
        color: #7f8c8d;
        font-size: 18px;
        font-weight: 700;
        padding-right: 10px;
    }

    .icon {
        width: 20px;
        height: 20px;
        display: none; // temp
    }

}
