@import '../../../../styles/variables';

.inner-button {
    display: flex;
    padding: 12px 24px;
    height: fit-content;
    width: fit-content;
    border-radius: 24px;
    cursor: pointer;
    align-items: center;

    p {
        align-items: center;
        display: flex;
        font-weight: 700;
        font-family: "Open Sans";
    }
}

.primary {
    & p {
        color: white !important;
    }

    &:hover {
        background-color: $primaryNewHover;
    }

    background-color: $primaryNew;



}

.secondary {
    & p {
        color: white !important;
    }

    &:hover {
        background-color: $primaryNew;

        & p {
            color: white !important;
        }
    }

    background-color: $primaryNewHover;

}

.tertiary {
    & p {
        color: $primaryNew !important;
    }

    padding: 12px 0px;
}

a {
    text-decoration: none !important;
}

.arrow {
    fill: $primaryNew;
    margin-left: 8px;
}

.icon--white {
    fill: #fff;
    max-height: 20px;
}

.inner-button--reverse {
    flex-direction: row-reverse !important;
}