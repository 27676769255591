.occupation-group {
    .group-header {
        height: 64px;
        position: sticky;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 64px;
        color: #e7ac5b;
        padding: 0px 20px;
        background-color: rgb(235, 216, 192);
        font-size: 20px;
        font-weight: 700;

        .occupation-indicator {
            font-size: 14px;
            font-weight: 700;
            color: #e7ac5b;

            &.medium {
                color: #e7ac5b;
            }
    
            &.high {
                color: #e74c3c;
            }
        }
    }
}