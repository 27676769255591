.visit_item {
  flex: 1;
  outline: none;
  display: flex;
  cursor: pointer;
  min-height: 90px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 9px;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: row;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

  .image {
    height: 64px;
    width: 116px;
    min-width: 116px;
    object-fit: cover;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: inset -1px 0 0 0 rgba(189, 195, 199, 0.4);
  }

  .info {
    flex: 1;
    display: flex;
    overflow: hidden;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    flex-direction: column;

    .title {
      padding: 0;
      font-size: 18px;
      margin: 0 0 7px;
      font-weight: 700;
      word-wrap: break-word;
    }

    .description {
      padding: 0;
      color: #7f8c8d;
      font-size: 14px;
      margin: 0 0 8px;
      font-weight: 400;
      word-wrap: break-word;
    }

    .price {
      padding: 0;
      color: #bdc3c7;
      margin: 0 0 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      display: inline-block;
    }
  }
}
