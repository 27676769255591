.donation_item {
    color: #e7ac5b;
    padding: 20px 20px;
    background-color: rgba(231, 172, 91, 0.32);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.donation_item_title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.donation_item_amount {
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 0;
}
