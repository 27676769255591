@import '../../styles/variables';

.searchbar {
  width: 100%;
  display: flex;
  min-height: 56px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  align-items: center;
}

.searchbar_input_container {
  flex: 1;
  margin-right: 12px;
  position: relative;
  border-radius: 10px;
  height: 56px;
  border: #bdc3c7 1px solid;
}

.searchbar_input_icon {
  top: 16px;
  left: 14px;
  position: absolute;
}

.searchbar_input {
  width: 100%;
  height: 54px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  padding-left: 50px;
  padding-right: 20px;
  border-radius: 100px;

  &::placeholder {
    color: #95a5a6;
  }
}

.searchbar_filter {
  margin: 0;
  padding: 0;
  color: $primary;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.searchbar_icon {
  padding: 5px;
  width: 38px;
  height: 38px;
}

.filter-button {
  background-color: $primary;
  width: 56px;
  height: 56px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.filter-text {
  color: #fff;
  font-size: 12px;
  margin-bottom: 5px;
}