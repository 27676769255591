@import '../../../../../styles/variables';

.reservation-footer {
    padding: 16px;
    border-top: 1px solid #EEEEEE;
    width: 100%;

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .button {
            width: 100%;
            justify-content: center;

            p {
                margin: 0;
                margin-left: 8px;
            }
        }

        .no-fill {
            padding: 12px;
            margin-right: 8px;

            svg {
                fill: none;
                margin: 0;
            }

            &:hover {
                background-color: $primaryNewHover;
            }

            p {
                display: none;
            }
        }
    }
}