@import '../../styles/variables';

.toolbar {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    color: white;
    height: 64px;
    display: flex;
    z-index: 1000;
    position: fixed;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-color: $primary;
}

.toolbar_menu {
    width: 100%;
    height: 64px;
    display: flex;
    position: absolute;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    flex-direction: row;
}

.toolbar_button {
    border: none;
    padding: 10px;
    outline: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}