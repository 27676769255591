.register_content {
  width: 100%;
  padding: 20px;
}

.opt_in_checkbox {
  padding: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.login_header {
  height: 250px;
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(../../assets/images/header_no_gradient.png);
  background-size: cover;
  background-position: center;
}
