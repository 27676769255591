.top-section {
    min-height: 640px;
    padding-top: 128px;
    position: relative;
    background-color: #fff;

    .background {
        position: absolute;
        top: 0;
        right: 0;

        .gradient {
            width: 100%;
            height: 640px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 94%);
            position: absolute;
            top: 0;
        }
    }


    .container {
        max-width: 1200px;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    .button-holder {
        display: flex;
        margin-top: 32px;

        div:nth-child(1) {
            margin-right: 12px;
        }
    }

    .download-buttons {
        position: absolute;
        bottom: 0px;
        right: 0px;

        img {
            cursor: pointer;
        }

        img:nth-child(1) {
            margin-right: 12px;
        }
    }

    .leader {
        max-width: 580px;

        .location-wrapper {
            padding: 10px 20px;
        }
    }

}