.order-confirmation-dialog .order-entry {
  margin-bottom: 8px;
  .title {
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
  }
}
