.how-it-works {
    max-width: 1920px;
    position: relative;
    margin: 0 auto;
    background-color: #FAFAFA;

    .container {
        max-width: 1200px;
        position: relative;
        width: 100%;
        margin: 0 auto;

        display: flex;
        flex-direction: row;
        padding: 80px 0;

        h2 {
            font-weight: 700;
            font-size: 40px;
            color: #041C32;
        }

        .tagline {
            color: #F86600;
            margin-top: 12px;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
        }

        .cards {
            display: flex;
            margin-top: 40px;
            flex-direction: column;

        }

    }

    .phone-pos {
        position: absolute;
        top: 0px;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;


    }
}