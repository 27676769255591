.privacy-policy.content {
  padding-top: 16px;

  @media screen and (max-width: 1024px) {
    .full-width {
      padding: 0 24px;
    }
  }

  ol {
    > li {
      list-style-type: none;
    }
  }
}
