.category_select_container_wrapper {
    width: 100%;
    display: flex;
    position: sticky;
    top: 62px;
    background-color: white;
    overflow-x: auto;
    flex-direction: row;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(189, 195, 199, 0.4);
    border-bottom: 1px solid rgba(189, 195, 199, 0.4);

    &::-webkit-scrollbar {
        display: none;
    }
}

.category_select_container {
    display: flex;
    top: 0;
    width: 100%;
    max-width: 800px;
    margin: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.category_padding {
    width: 8px;
    flex-shrink: 0;
}

.category_item {
    color: #bdc3c7;
    flex-shrink: 0;
    cursor: pointer;
    padding: 14px 0;
    font-size: 15px;
    font-weight: 700;
    margin-left: 8px;
    margin-right: 8px;

    &.selected {
        color: #e7ac5b;
        border-radius: 3px;
        border-bottom: 3px solid #e7ac5b;
    }

}
