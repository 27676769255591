.reservation-header {
    padding: 16px;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .copy {
            display: flex;
            flex-direction: row;
            align-items: center;

            .divider {
                margin: 0 16px;
                height: 24px;
                width: 1px;
                background-color: #EEEEEE;
            }

            .step {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #9E9E9E;
            }
        }

        .close {
            fill: #9E9E9E;
            width: 14px;
            height: 14px;
        }
    }
}