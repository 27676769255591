.order_item_amount_container {
  width: 112px;
  height: 36px;
  display: flex;
  margin-top: 8px;
  border-radius: 4px;
  flex-direction: row;
  border: 1px solid rgba(189, 195, 199, 0.4);

  .order_item_amount {
    flex: 1;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .order_item_amount_min {
    width: 12px;
    height: 12px;
  }

  .order_item_amount_plus {
    width: 12px;
    height: 12px;
    fill: #7f8c8d;
  }

  .order_item_amount_value {
    flex: 1;
    color: #222d39;
    font-size: 17px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }
}
