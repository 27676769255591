@import '../../styles/variables';

.search_sort_options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: row;

  &::-webkit-scrollbar {
    display: none;
  }
}

.header {
  padding: 20px 15px;
  width: 100%;

  font-weight: 800;
  font-size: 14px;

  display: flex;
  justify-content: space-between;

  color: #e7ac5b;
  background-color: lighten(#e7ac5b, 25%);

  .delete {
    color: $primary;
  }
}