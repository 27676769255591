@import '../../styles/variables';

.dot_indicator {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .dot {
        width: 10px;
        height: 10px;
        margin: 0 2px;
        border-radius: 16px;
        background-color: white;
        border: solid 2px $primary;

        &.active {
            background-color: $primary;
        }
    }
}