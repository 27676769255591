.input-group-error {
  color: #c0392b !important;
  display: none;
  text-align: left !important;
  margin-left: 0px !important;

  &.visible {
    display: block;
  }
}
