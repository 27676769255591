.quick-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 0 rgba(189, 195, 199, 0.4),
    inset -1px 0 0 0 rgba(189, 195, 199, 0.4);

  &:last-child {
    box-shadow: inset 0 -1px 0 0 rgba(189, 195, 199, 0.4);
  }

  h1 {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.54px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}

a.quick-action {
  text-decoration: none;

  h1 {
    color: black;
  }
}
