.modal-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 9999;
  position: fixed;
  overflow-y: auto;
  justify-content: center;
  background-color: rgba(34, 45, 57, 0.6);
}
