.filter_search_item {
  display: flex;
  cursor: pointer;
  padding: 20px 16px;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.filter_search_item_details {
  flex: 1;
  overflow: hidden;
  word-wrap: break-word;

  .title {
    color: black;
    font-size: 15px;
    font-weight: 800;
  }

  .subtitle {
    font-size: 12px;
    color: #bdc3c7;
    margin-top: 4px;
  }
}

.filter_search_item_selector {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
  opacity: 0.8;

  &.delete {
    width: 14px;
    height: 14px;
  }
}
