.reservation-dialog {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #fff;
  top: 0;
  bottom: 0;
  z-index: 9999;
  font-family: 'Open Sans' !important;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}