.notifications {
  left: 20px;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  flex-direction: column;
}
