.table_dialog {

}

.table_dialog_image {
    border-radius: 6px;
}

.table_dialog_title {
    margin: 0;
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    padding-top: 20px;
    text-align: center;
}

.table_dialog_description {
    margin: 0;
    color: #7f8c8d;
    font-size: 14px;
    font-weight: 400;
    padding-top: 8px;
    text-align: center;
    padding-bottom: 8px;
}

.table_dialog_label {
    padding: 0;
    color: #7f8c8d;
    font-size: 12px;
    font-weight: 700;
    margin: 24px 0 0 0;
    text-transform: uppercase;
}
