.legend {
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px;

  .title {
    margin: 0px 0px 8px 0px;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
  }
}
