.restaurant_header {
  display: flex;
  position: relative;
  flex-direction: column;

  .avatar {
    margin: 16px auto;
  }
}

.restaurant_header_crowded {
  margin: 4px 0;
  color: #c0392b;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.restaurant_header_image_holder {
  width: 100%;
  height: 180px;
  position: absolute;
}

.restaurant_header_image_holder_content {
  height: 100%;
  position: relative;
}

.restaurant_header_image_overlay {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
}

.restaurant_header_button {
  z-index: 10;
  width: 44px;
  height: 44px;
  margin-top: 20px;
  outline: none;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid rgba(189, 195, 199, 0.4);

  .icon {
    width: 20px;
    height: 20px;
  }
}

.restaurant_header_buttons_right {
  position: absolute;
  right: 0;
}

.restaurant_back_button {
  margin-left: 20px;
}

.restaurant_scan_button {
  margin-right: 20px;
}

.restaurant_profile_button {
  margin-right: 20px;
}

.restaurant_header_text_wrapper {
  width: 100%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.restaurant_header_name {
  margin: 4px 0;
  color: black;
  font-size: 24px;
  font-weight: 700;
}

.restaurant_header_description {
  margin: 4px 0;
  color: #7f8c8d;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.restaurant_header_location {
  margin: 6px 0;
  color: #bdc3c7;
  font-size: 14px;
  font-weight: 400;
}

.restaurant_header_like_wrapper {
  margin-top: 2px;
  display: inline-block;
}

.restaurant_header_like_icon {
  width: 22px;
  height: 22px;
  margin-left: 6px;
}

.restaurant_header_location_icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.restaurant_header_arrow {
  width: 24px;
  height: 24px;
  margin: 8px auto 0 auto;
}
