@import '../../../styles/variables';

.checkbox_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.invalid {
    .checkbox {
      border-color: red;
    }

    .checkbox_text {
      color: red;
    }
  }

  .checkbox {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    display: flex;
    margin-right: 10px;
    border-radius: 4px;
    align-items: center;
    justify-items: center;
    border: 2px solid $primary;

    &.selected {
      background-color: $primary;
    }

    .checkbox_check_icon {
      width: 20px;
      height: 20px;
    }
  }

  .checkbox_text {
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
}