@import '../../styles/variables';

.payment_result_content {
    width: 100%;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    flex-direction: column;

    .payment_header_name {
        color: $primary;
        font-weight: 700;
        margin-top: 20px;
    }

    p {
        color: #7f8c8d;
    }

    a {
        color: #E7AC5B;
    }
}