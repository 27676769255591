.drawer_menu_container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.5);
    transition: background-color 0.3s;

    &.enter {
        background-color: rgba($color: #000000, $alpha: 0);

        .drawer_menu {
            transform: translateX(-100%);
        }
    }

    &.enter_active {
        background-color: rgba($color: #000000, $alpha: 0.5);

        .drawer_menu {
            transform: translateX(0%);
            transition: transform 300ms ease-in-out;
        }
    }

    &.exit {
        background-color: rgba($color: #000000, $alpha: 0);

        .drawer_menu {
            transform: translateX(0%);
        }
    }

    &.exit_active {
        background-color: rgba($color: #000000, $alpha: 0);

        .drawer_menu {
            transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
        }
    }
}

.drawer_menu {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    position: fixed;
    max-width: 600px;
    will-change: transform;
    flex-direction: column;
    background-color: white;
}

.drawer_menu_content {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.drawer_menu_close_icon {
    width: 100%;
    height: 100%;
}

.drawer_menu_close_button {
    width: 28px;
    height: 28px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 16px;
    margin-left: 16px;
    background-color: transparent;
}
