.top-bar {
    padding: 8px 0;
    background-color: white;


    .container {
        max-width: 1200px;
        position: relative;
        width: 100%;
        margin: 0 auto;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 94px;
        }

        .button-holder {
            display: flex;

            div:nth-child(1) {
                margin-right: 12px;
            }
        }
    }
}