.dialog {
  margin: auto auto;
  padding: 16px;
  flex: 1;

  > p {
    color: #7f8c8d;
    font-size: 16px;
    letter-spacing: -0.57px;
    text-align: center;
    margin: 10px;
  }

  &.center {
    align-items: center;
    margin: auto auto;
  }

  .dialog_content {
    width: 100%;
    padding: 20px;
    border-radius: 9px;
    background-color: white;
    border: 1px solid rgba(189, 195, 199, 0.4);

    &.full_height {
      height: min-content;
    }
  }

  @media only screen and (min-width: 600px) {
    .dialog_content {
      max-width: 600px;
      margin: auto;
    }
  }
}
