.slide {
  top: 0;
  left: 0;
  height: 100%;
  will-change: transform;
  transition: transform 500ms ease-in-out;
  scroll-snap-align: center;

  .content {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;

    &.inline {
      border-radius: 0;
    }
  }

  &.inline {
    padding: 0;
  }
}

.image_carousel {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;

  &.inline {
    margin-bottom: 0;
  }

  .slides-container {
    scroll-snap-type: x mandatory;
    overflow-x: hidden;
    scroll-behavior: smooth;
    display: flex;
  }
}
