.reservation-ticket-screen {
  padding-top: 64px !important;
  p,
  h2 {
    text-align: center;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  p {
    font-size: 16px;
    opacity: 0.6;
    margin-top: 0;
  }
  .button {
    margin-top: 58px;
  }

  .alt_action {
    margin-top: 24px;
  }
}
