.search_result {
  width: 100%;
  display: flex;
  padding-left: 20px;
  align-items: center;
  flex-direction: row;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.search_result_text {
  flex: 1;
  margin: 0;
  color: black;
  font-size: 24px;
  font-weight: 700;
  padding-right: 20px;
}

.search_result_close {
  width: 14px;
  height: 14px;
  fill: #e7ac5b;
}
