.radio-group {
  margin-top: 24px;
  margin-bottom: 24px;
  &.invalid {
    label {
      color: red;
    }
  }

  .radio-option {
    flex-direction: row;
    display: flex;
    align-items: center;

    &:nth-child(n + 2) {
      margin-top: 8px;
    }

    input {
      width: 16px;
      height: 16px;
      // border-color: #e7ac5b;
    }

    label {
      margin: 0;
      margin-left: 12px;
      color: #000;
    }
  }
}
